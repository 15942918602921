body {
  /*background-image: url('../public/nycbackground.jpg');*/
  background-image: url('./assets/images/nycbackground.jpg');
  /*background-size: contain;*/
  /*background-repeat: no-repeat;*/
  background-size: 100% 100%;
  background-attachment: fixed;
  opacity: 0.92;
  /*width: 100vw;
  height: 100vh;*/
}

.App {
  background: #eeeeee;
  margin: auto;
  width: 1400px;
  text-align: left;
  min-height: 100vh;
  height: 100%;
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header {
    font-size: 20px;
    /*justify-content: space-evenly;*/
    margin: auto;
}

.HeaderItems {
    background: #888888;
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: left;

}

.HeaderItems a {
    color: black;
    font-weight: 700;
    text-decoration: none;
    margin-right: 80px;
}

.SubHeader {
    background: #bbbbbb;
    font-size: 16px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}


@media only screen and (max-width: 768px) {
    .HeaderItems {
    
    }
    .HeaderItems a {
        font-size: 12px;
        margin-right: 40px;
    }
    /*
    .navMenu {
        display: block;
        background-image: url('../images/mobile-menu-32x32.png');
        background-repeat: no-repeat;
    }*/
}



.Home {
  padding-left: 10px;
  padding-right: 10px;

}

.HomeHeading {
  margin-top: 30px;
  font-size: 16px;
}

.HomeImage {
  margin-top: 30px;
}

.HomeImage img {
  max-width: 100%;
}

.NewsHeading {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 700;
}

.NewsTabs {
  margin-top: 30px;
}


.DadJoke {
  margin-left: 10px;
  margin-top: 60px;
  padding-bottom: 40px;
}

.Books {
  padding-left: 10px;

}

.Resume {
  margin-top: 20px;
  padding-left: 10px;
}

.ResumeSectionHeader {
  font-size: 21px; font-weight: bold;
}

.ResumeSchoolName {
  font-size: 18px; font-weight: bold;
}

.JobTitle {
  font-size: 21px; font-weight: bold;
}

.JobCompany {
  font-size: 18px; font-weight: bold;
}

.Orange {
  background: orange;
  height: 100%;
  min-height: 100vh;
}

.OrangeMain {
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
}

.OrangeDownload {
  margin-left: 10px;
  margin-top: 10px;
}

.OrangeImage {
  margin-left: 10px;
  margin-top: 20px;
}
